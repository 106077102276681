import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    mapReady: Boolean,
    stateSelection: String,
  }

  filterTableController() {
    var div = document.getElementById("yield-data-filter-table")
    return this.application.getControllerForElementAndIdentifier(div, "filter-table")
  }

  initialize() {
    this.locationData = JSON.parse(this.data.get("locationData"))
    this.latLongKeys = {}
  }

  mapReadyValueChanged() {
    if (this.mapReadyValue == true) {
      // map is loaded & ready
      this.colorStatesWithValues()
      this.setLocationValues()
      simplemaps_usmap.hooks.zoomable_click_state = this.onStateClick.bind(this)
      simplemaps_usmap.hooks.back = this.onStateClear.bind(this)
      setTimeout(function() {
        this.handleQueryParam()
      }.bind(this), 750)
    }
  }

  colorStatesWithValues() {
    var statesAndTotals = {}
    this.locationData.map((item) => {
      statesAndTotals[item.state] ||= 0
      statesAndTotals[item.state] += 1
    })

    Object.keys(statesAndTotals).map((key) => {
      simplemaps_usmap_mapdata.state_specific[key].color = "#c1a01e"
      simplemaps_usmap_mapdata.state_specific[key].hover_color = "#d0af21"
      simplemaps_usmap_mapdata.state_specific[key].description = `${statesAndTotals[key]} Result${statesAndTotals[key] == 1 ? '' : 's'}`
    })
  }

  latLongKeyLookup(key) {
    if (this.latLongKeys[key]) {
      return this.latLongKeys[key]
    }

    this.locationData.map((item) => {
      this.latLongKeys[item.latitude+item.longitude] ||= 0
      this.latLongKeys[item.latitude + item.longitude] += 1
    })

    return this.latLongKeys[key]
  }

  setLocationValues() {
    this.locationData.map((item, index) => {
      var randomizedLat = parseFloat(item.latitude) + ((Math.random() > 0.5 ? 0.01 : -0.09) + Math.random() * 0.33)
      var randomizedLng = parseFloat(item.longitude) + ((Math.random() > 0.5 ? 0.01 : -0.09) + Math.random() * 0.33)
      var isDuplicateLocation = this.latLongKeyLookup(item.latitude + item.longitude) > 1

      simplemaps_usmap_mapdata.locations[index] = {
        lat: isDuplicateLocation ? randomizedLat : item.latitude,
        lng: isDuplicateLocation ? randomizedLng : item.longitude,
        name: `${item.year_grown} - ${item.data_type == "plot_trial" ? "Farmer Grower" : "Farmer Grower"}`,
        color: item.data_type == "plot_trial" ? "#e91e63" : "#e91e63",
        size: 25,
        type: "marker",
        description: `<strong>${item.product} - ${item.relative_maturity} Day</strong><br/>${`<strong>${item.yield} ${item.usage == "S" ? "ton/acre" : "bu/acre"}`} - ${item.usage == "S" ? "Silage" : "Grain"}</strong>${item.yield_notes == null ? '' : "<br/>"+item.yield_notes}${item.grower_notes == null ? '' : "<br/>"+item.grower_notes}${item.test_weight == null ? '' : "<br>Test Weight: "+item.test_weight}${item.percent_moisture == null ? '' : "<br>Percent Moisture: "+item.percent_moisture}`
      }
    })
    if (window.simplemaps_usmap && window.simplemaps_usmap.refresh) {
      window.simplemaps_usmap.refresh()
    }
  }

  stateSelectionValueChanged() {
    if (window.simplemaps_usmap) {
      if (simplemaps_usmap.back) {
        if (this.stateSelectionValue == "") {
          simplemaps_usmap.back()
          this.filterTableController().filterByState("")
        } else {
          simplemaps_usmap.state_zoom(this.stateSelectionValue)
        }
      }
    }
  }

  onStateClick(state) {
    if (this.filterTableController()) {
      this.filterTableController().filterByState(state)
    }
    this.stateSelectionValue = state
  }

  onStateClear() {
    if (this.filterTableController()) {
      this.filterTableController().filterByState("")
    }
    this.stateSelectionValue = ""
  }

  handleQueryParam() {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var yieldStateParam = undefined
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == "yield_state") {
        yieldStateParam = pair[1]
      }
    }

    if (yieldStateParam !== undefined) {
      this.onStateClick(yieldStateParam)
      // scroll to yield data section
      var elem = document.querySelector("#yield-data")
      var yOffset = -100
      var y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

}
