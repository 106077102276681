import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const seedSubtotal = parseFloat(this.element.dataset.orderSeedSubtotal)
    const email = this.element.dataset.orderEmail
    const phoneNumber = this.element.dataset.orderPhoneNumber
    const streetAddress = this.element.dataset.orderStreetAddress
    const city = this.element.dataset.orderCity
    const state = this.element.dataset.orderState
    const zipcode = this.element.dataset.orderZipcode

    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'SubmitOrder',
        'conversionValue': seedSubtotal
      })
      window.dataLayer.push({
        'set': 'user_data',
        'sha256_email_address': email,
        'sha256_phone_number': phoneNumber,
        'sha256_street_address': streetAddress,
        'sha256_city': city,
        'sha256_state': state,
        'sha256_zipcode': zipcode
      })
    }
  }

}