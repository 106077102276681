import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import L from '../js/leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet.pattern';

export default class extends Controller {
  static targets = [
    "ZipcodeForm", "ZipcodeInput", "ZipcodeLookupResult", "ZipcodeError",
    "Map", "MapContainer", "MapOverlay",
    "AllListingsContainer", "ListingContainer", "FilterPurposeContainer",
    "AdminEditInstructionsStep1", "AdminMapKeyControlsContainer", "AdminMapKeyRadioButtons", "AdminMapKeyRadioButtonRow", "AdminNewMapKey",
    "DisplayedMapTitleContainer", "DisplayedMapTitleText", "ResultsCount"
  ]

  hybridsController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='hybrids']"), 'hybrids')
  }

  connect() {
    this.isAdminEditing = false
    this.drawControl = null
    this.selectedHybridJson = null
    this.selectedPurpose = null
    this.selectedCountyId = null
    this.filteredHybridMapData = []
    this.countyFilteredHybrids = []
    this.hybridsFilterListingMatches = [] // external controller filters
    this.editingMapKey = null
    this.isCrdMode = false;
    this.crdData = null
    this.isFindingDeadzones = false
    this.fillOpacity = 0.75
    this.fillColorGrain = '#d0af2a'
    this.fillColorSilage = '#243746'
    this.crdBorderColors = ['#FF6347', '#4682B4', '#32CD32', '#FFD700', '#6A5ACD', '#FF69B4', '#20B2AA', '#F08080', '#9370DB', '#3CB371', '#7B68EE','#DB7093','#48D1CC','#C71585','#FFA07A','#BDB76B','#6B8E23','#FF4500','#2E8B57','#D2691E','#5F9EA0','#DAA520','#98FB98','#DB7093','#CD853F','#DDA0DD','#B0E0E6','#BC8F8F','#4169E1','#8B4513','#FA8072'];
    this.stripedPattern = new L.StripePattern({
      color: this.fillColorGrain,
      spaceColor: this.fillColorSilage,
      opacity: this.fillOpacity,
      spaceOpacity: this.fillOpacity,
      angle: 135
    });

    this.ZipcodeFormTarget.addEventListener("submit", this.onZipcodeSubmit.bind(this))
    this.initMap()
    this.DisplayedMapTitleTextTarget.innerHTML = this.DisplayedMapTitleTextTarget.dataset.defaultText
  }

  countyBorderStyle() {
    return {
      color: '#c3cdd5', // Border color
      // color: 'transparent',
      weight: 1,       // Border width
      fillOpacity: 0,   // No fill
      fillColor: null
    };
  }

  countySelectedStyle() {
    return {
      fillColor: "#ffaa55",
      fillOpacity: this.fillOpacity
    }
  }

  setMapTileLayer() {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    // L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    //   attribution: '© OpenStreetMap contributors, © CARTO'
    // }).addTo(this.map);
  }

  initMap() {
    this.map = null
    this.countyLayers = []
    this.countyGroup = L.layerGroup();

    this.map = L.map(this.MapTarget, {
      attributionControl: false,
      scrollWheelZoom: false,
    }).setView([40.7922, -96.70253], 5);

    this.stripedPattern.addTo(this.map)
    this.setMapTileLayer()

    this.drawCountyBoundaries()
    this.drawStateBoundaries()
    this.addDrawControl()

    this.countyGroup.addTo(this.map)

    this.resetMapToCountyMode()
  }

  drawCountyBoundaries() {
    fetch('/counties.geojson')
      .then(response => response.json())
      .then(data => {
        L.geoJson(data, {
          style: this.countyBorderStyle.bind(this),
          onEachFeature: (feature, layer) => {
            this.countyLayers.push(layer)

            layer.on('click', () => {
              this.handleCountyClick(feature, layer);
            });
          }
        }).addTo(this.map);
      })
      .catch(error => console.error('Error loading the GeoJSON file:', error))
  }

  drawStateBoundaries() {
    fetch('/states.geojson') // Ensure you have the correct path to your states GeoJSON file
      .then(response => response.json())
      .then(data => {
          L.geoJson(data, {
              style: () => ({
                  color: '#ababab', // Darker border color for states
                  weight: 2,        // Thicker border width for states
                  fillOpacity: 0,   // Typically, state boundaries wouldn't be filled
              })
          }).addTo(this.map);
      })
      .catch(error => console.error('Error loading the states GeoJSON file:', error));
  }

  addDrawControl() {
    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        marker: false,
        circlemarker: false,
        rectangle: true // Enable drawing rectangle
      }
    });

    this.map.on('draw:created', this.handleDrawCreated.bind(this));
  }

  toggleCrdMode() {
    this.isCrdMode = !this.isCrdMode;

    if (this.isCrdMode) {
      this.fetchCrdDataAndHighlight();
    } else {
      this.resetMapToCountyMode();
    }
  }

  async fetchCrdDataAndHighlight() {
    if (this.crdData == null) {
      const response = await fetch('/crd_counties.geojson');
      this.crdData = await response.json();
      this.assignColorsToCrds();
    }
    this.highlightCrdBoundaries();
  }

  assignColorsToCrds() {
    let colorIndex = 0;

    this.crdData.forEach(crd => {
      crd.color = this.crdBorderColors[colorIndex % this.crdBorderColors.length];
      colorIndex++;
    });
  }

  highlightCrdBoundaries() {
    this.crdData.forEach(crd => {
      crd.Counties.forEach(county => {
        const countyLayer = this.countyLayers.find(layer => layer.feature.properties.GEOID === county.GEOID);
        if (countyLayer) {
          this.countyGroup.addLayer(countyLayer);
          countyLayer.setStyle({
            color: crd.color,
            weight: 2,
            fillOpacity: this.fillOpacity,
            // fillColor: 'transparent'
          });

          // Add a tooltip with the CRD district number
          countyLayer.bindTooltip(`CRD: ${crd.District}`, {
            permanent: false, // Set to false if you only want it to show on hover
            direction: 'center',
            className: 'bg-white font-bold opacity-75 rounded p-1'
        });
        }
      });
    });
  }

  resetMapToCountyMode() {
    this.countyGroup.clearLayers();
    this.drawCountyBoundaries(); // Assuming this method redraws the initial county boundaries
    if (this.editingMapKey) {
      this.highlightCountiesForMapKey(this.editingMapKey)
    }
  }

  async onFindDeadZonesClick(e) {
    e.preventDefault()

    this.isFindingDeadzones = !this.isFindingDeadzones

    this.resetCountyhighlighting()
    this.map.setView([40.7922, -96.70253], 5);

    if (this.isFindingDeadzones) {
      const listingIds = this.ListingContainerTargets.map(listingContainer => JSON.parse(listingContainer.dataset.json).id);

      const request = new FetchRequest('GET', `/hybrids/${listingIds}/all_counties`, { responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const responseJson = await response.json
        console.log('responseJson', responseJson)

        this.filteredHybridMapData = responseJson
        let deadZoneCounties = []

        this.countyLayers.forEach((layer) => {
          if (responseJson.includes(layer.feature.properties.GEOID)) {
            // do nothing
          } else {
            deadZoneCounties.push(layer.feature.properties.GEOID)
          }
        })
        console.log('deadZoneCounties', deadZoneCounties)
        this.highlightMapCountiesForFilters([{ "purpose_silage": deadZoneCounties }])
      }
    }
  }

  resetCountyhighlighting() {
    this.countyGroup.eachLayer((layer) => {
      layer.setStyle({ fillOpacity: 0, fillColor: null })
    })
  }

  handleCountyClick(feature, countyLayer) {
    if (this.isAdminEditing) {
      if (this.isCrdMode) {
        const clickedCountyGEOID = countyLayer.feature.properties.GEOID;

        // Find the CRD that contains the clicked county
        const containingCrd = this.crdData.find(crd =>
          crd.Counties.some(county => county.GEOID === clickedCountyGEOID));

        if (containingCrd) {
          // Extract GEOIDs of all counties within the same CRD
          const countyGEOIDs = containingCrd.Counties.map(county => county.GEOID);

          // Determine if any county in the CRD is already selected
          const isAnyCountySelected = countyGEOIDs.some(geoId =>
            this.filteredHybridMapData.some(mapData =>
              mapData[this.editingMapKey] && mapData[this.editingMapKey].includes(geoId)));

          // Set the operation based on whether any county in the CRD is already selected
          const operation = isAnyCountySelected ? 'remove' : 'add';

          // Apply the style to all counties in the CRD
          containingCrd.Counties.forEach(county => {
            const layer = this.countyLayers.find(layer => layer.feature.properties.GEOID === county.GEOID);
            if (layer) {
              if (operation === 'add') {
                layer.setStyle(this.countySelectedStyle());
              } else { // operation === 'remove'
                layer.setStyle({ fillOpacity: 0 }); // Reset to default style or another style indicating deselection
              }
            }
          });

          // Now, send these GEOIDs to the backend with the determined operation
          this.updateCountiesInCrd(countyGEOIDs, operation);
        }
      } else {
        // Logic for non-CRD mode; assuming you meant to call 'handleCountyClick' recursively was a mistake and should directly apply styles or logic here
        // Directly handle single county selection or deselection
        this.onCountySelect(countyLayer); // Ensure this method exists or replace with appropriate logic
      }
    }
  }

  async handleDrawCreated(e) {
    var type = e.layerType,
        layer = e.layer;

    if (type === 'rectangle') {
      const bounds = layer.getBounds();
      const delay = ms => new Promise(res => setTimeout(res, ms));

      for (const countyLayer of this.countyLayers) {
        if (bounds.intersects(countyLayer.getBounds())) {
          await delay(100); // Wait for 200ms to prevent backend race condition

          this.onCountySelect(countyLayer, 'add')
        }
      };
    }
  }

  onCountySelect(countyLayer, fromDrawBox = false) {
    let operation = null

    if (this.isAdminEditing) {
      const isCountySelected = this.filteredHybridMapData.some((mapData) => {
        return mapData[this.editingMapKey] && mapData[this.editingMapKey].includes(countyLayer.feature.properties.GEOID)
      })

      const existingGroupLayer = this.countyLayers.find(layer => layer.feature.properties.GEOID === countyLayer.feature.properties.GEOID);

      if (isCountySelected && !fromDrawBox) {
        existingGroupLayer.setStyle({fillOpacity: 0})
        countyLayer.setStyle({fillOpacity: 0})
        operation = 'remove'
      } else {
        existingGroupLayer.setStyle(this.countySelectedStyle())
        countyLayer.setStyle(this.countySelectedStyle())
        operation = 'add'
      }
      operation = fromDrawBox ? 'add' : operation

      this.updateMapDataForCounty(countyLayer, operation)
    }
  }

  async updateCountiesInCrd(countyGEOIDs, operation = "add") {
    const payload = {
      county_geo_ids: countyGEOIDs,
      operation: operation,
      map_key: this.editingMapKey,
    };

    const request = new FetchRequest('PUT', `/hybrids/${this.selectedHybridJson.id}/update_map_data_for_county`, {
      body: JSON.stringify(payload),
      responseKind: 'json'
    });
    const response = await request.perform();

    if (response.ok) {
      const responseJson = await response.json
      this.filteredHybridMapData = responseJson
    } else {
      // Handle error
      console.error("Error updating CRD", response);
    }
  }

  async updateMapDataForCounty(countyLayer, operation) {
    const request = new FetchRequest('PUT', `/hybrids/${this.selectedHybridJson.id}/update_map_data_for_county?map_key=${this.editingMapKey}&county_geo_id=${countyLayer.feature.properties.GEOID}&operation=${operation}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.filteredHybridMapData = responseJson

      // this.updateMapAndView(responseJson)
      // this.DisplayedMapTitleTextTarget.innerHTML = responseJson.display_name
    } else {
      console.log('error', response)
    }
  }

  async onZipcodeSubmit(e) {
    e.preventDefault()

    if (this.ZipcodeInputTarget.value !== '') {
      const formMethod = this.ZipcodeFormTarget.method

      const request = new FetchRequest(formMethod, this.ZipcodeFormTarget.action, { body: JSON.stringify({ zipcode: this.ZipcodeInputTarget.value }), responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const responseJson = await response.json

        this.drawCountyBoundingBox(responseJson)
        this.ZipcodeLookupResultTarget.innerHTML = `${responseJson.display_name} - Lat: ${responseJson.lat} - Lon: ${responseJson.lon} - State: ${responseJson.state}`
        this.ZipcodeLookupResultTarget.classList.remove('hidden')
        this.ZipcodeErrorTarget.classList.add('hidden')

        const matchingCountyId = this.findCountyByLatLng(responseJson.lat, responseJson.lon)
        this.selectedCountyId = matchingCountyId

        if (matchingCountyId) {
          this.filterHybridsByCountyId(matchingCountyId)
        }

        if (this.hybridsController()) {
          this.hybridsController().ZipcodeInputTarget.value = this.ZipcodeInputTarget.value
          this.hybridsController().onZipcodeChange({ target: this.hybridsController().ZipcodeInputTarget })
        }
      } else {
        const responseJson = await response.json

        this.ZipcodeErrorTarget.innerHTML = responseJson.error
        this.ZipcodeErrorTarget.classList.remove('hidden')
        this.ZipcodeLookupResultTarget.classList.add('hidden')

        // Remove existing bounding box if any
        if (this.boundingBoxLayer) {
          this.map.removeLayer(this.boundingBoxLayer);
        }
      }
    } else {
      // zipcode is blank. filter by all hybrids
      this.filterHybridsByCountyId(null)
      if (this.boundingBoxLayer) {
        this.map.removeLayer(this.boundingBoxLayer);
      }
    }
  }

  findCountyByLatLng(lat, lng) {
    let matchingCountyGEOID = null;

    this.countyLayers.find((layer) => {
      if (layer.getBounds().contains(L.latLng(lat, lng))) {
        matchingCountyGEOID = layer.feature.properties.GEOID;
      }
    })

    return matchingCountyGEOID;
  }

  drawCountyBoundingBox(responseJson) {
    this.map.setView([responseJson.lat, responseJson.lon], 7);

    // Draw bounding box
    const boundingBox = responseJson.bounding_box.map(coord => parseFloat(coord));
    const southWest = L.latLng(boundingBox[0], boundingBox[2]);
    const northEast = L.latLng(boundingBox[1], boundingBox[3]);
    const bounds = L.latLngBounds(southWest, northEast);

    // Remove existing bounding box if any
    if (this.boundingBoxLayer) {
      this.map.removeLayer(this.boundingBoxLayer);
    }

    // Add new bounding box layer
    this.boundingBoxLayer = L.rectangle(bounds, {color: "#ff7800", weight: 1}).addTo(this.map);
  }

  async filterHybridsByCountyId(countyId) {
    const url = countyId ? `/hybrid-finder-filter-results?county_id=${countyId}` : '/hybrid-finder-filter-results'
    const request = new FetchRequest('GET', url, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      console.log('responseJson', responseJson)

      this.countyFilteredHybrids = responseJson
      this.updateListingResults()
    } else {
      console.log('error', response)
    }
  }

  updateListingResults() {
    let combinedFilteredMatches = []

    if (this.countyFilteredHybrids.length === 0) {
      combinedFilteredMatches = this.hybridsFilterListingMatches
    } else if (this.hybridsFilterListingMatches.length === 0) {
      combinedFilteredMatches = this.countyFilteredHybrids
    } else {
      combinedFilteredMatches = this.countyFilteredHybrids.filter(hybrid => this.hybridsFilterListingMatches.map((match) => match.id).includes(parseInt(hybrid.api_id)))
    }

    if (this.selectedPurpose && this.selectedCountyId) {
      combinedFilteredMatches = this.filterHybridsByZipcodeAndFilters(combinedFilteredMatches)
    }

    const visibleCount = this.updateListingsContainer(combinedFilteredMatches)

    if (this.hasResultsCountTarget) {
      this.ResultsCountTargets.forEach((target) => {
        target.innerHTML = visibleCount / 2 // 2 sets of ListingContainers
      })
    }
  }

  filterHybridsByZipcodeAndFilters(hybrids) {
    let filteredMatches = []

    if (hybrids.length) {
      filteredMatches = hybrids.filter(hybrid => {
        if (hybrid.county_data) {
          return Object.keys(hybrid.county_data).some(mapKey => {
            const mapKeyMatchesPurpose = mapKey.indexOf(`purpose_${this.selectedPurpose}`) === 0
            const mapKeyMatchesCountyId = hybrid.county_data[mapKey].includes(this.selectedCountyId.toString())
            return mapKeyMatchesPurpose && mapKeyMatchesCountyId
          })
        } else {
          return false
        }
      })
    }

    return filteredMatches
  }

  updateListingsContainer(hybrids) {
    const hybridApiIds = hybrids.map(hybrid => parseInt(hybrid.api_id || hybrid.id))
    let visibleCount = 0;

    this.ListingContainerTargets.forEach((listingContainer) => {
      if (hybridApiIds.includes(parseInt(listingContainer.dataset.apiId))) {
        listingContainer.classList.remove('hidden')
        visibleCount++;
      } else {
        listingContainer.classList.add('hidden')
      }
    })

    return visibleCount;
  }

  onHybridsFilterMatches(hybridsFilterListingMatches) {
    this.hybridsFilterListingMatches = hybridsFilterListingMatches

    this.updateListingResults()
  }

  onFilterClick(type, value) {
    if (type == 'purpose') {
      this.resetCountyhighlighting()
      this.selectedPurpose = value.toLowerCase()
    }

    this.highlightMapCountiesForFilters(this.filteredHybridMapData)
    this.updateListingResults()
  }

  onClearFilters() {
    this.resetCountyhighlighting()
    this.map.setView([40.7922, -96.70253], 5);
    this.isAdminEditing = false
    this.selectedHybridJson = null
    this.selectedPurpose = null
    this.filteredHybridMapData = []
    this.countyFilteredHybrids = []
    this.editingMapKey = null

    this.ListingContainerTargets.forEach((listingContainer) => listingContainer.classList.remove('bg-gray-300', 'hidden'))

    this.AdminMapKeyRadioButtonRowTargets.forEach((row) => {
      row.classList.remove('bg-gray-300');
      row.selected = false
    });
  }

  onAdminEditClick(e) {
    this.isAdminEditing = !this.isAdminEditing

    if (this.isAdminEditing) {
      e.currentTarget.innerHTML = "Exit Admin Edit Mode"
      e.currentTarget.classList.add('!bg-red-500')
      this.MapContainerTarget.classList.add('ring', 'ring-red-500')
      this.map.setView([40.7922, -96.70253], 5);

      this.showAdminEditInstructions()
    } else {
      e.currentTarget.innerHTML = "Enter Admin Edit Mode"
      e.currentTarget.classList.remove('!bg-red-500')
      this.MapContainerTarget.classList.remove('ring', 'ring-red-500')

      this.hideAdminEditControls()
    }
  }

  onListingClick(e) {
    const selectedListingJson = JSON.parse(e.currentTarget.dataset.json)
    const selectedListingId = selectedListingJson.id

    // only allow one to be selected at a time
    this.ListingContainerTargets.forEach((listingContainer) => {
      const listingJson = JSON.parse(listingContainer.dataset.json)
      if (listingJson.id !== selectedListingId) {
        listingContainer.classList.remove('bg-gray-300')
      }
    })

    this.resetCountyhighlighting()

    if (e.currentTarget.classList.contains('bg-gray-300')) {
      // unselecting
      e.currentTarget.classList.remove('bg-gray-300')
      this.selectedHybridJson = null
    } else {
      // selecting
      e.currentTarget.classList.add('bg-gray-300')
      this.selectedHybridJson = selectedListingJson

      this.fetchMapDataForHybrid(selectedListingJson)
    }

    if (this.isAdminEditing) {
      this.showAdminEditInstructions()
    } else {
      // remove this when filters wired up
      this.showAdminEditInstructions()
    }
  }

  async fetchMapDataForHybrid(listingJson) {
    const request = new FetchRequest('GET', `/hybrids/${listingJson.id}/map_data?name=${listingJson.name}&days_to_maturity=${listingJson.days_to_maturity}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.filteredHybridMapData = responseJson

      this.updateAdminMapKeyRadioButtons(responseJson)
      this.highlightMapCountiesForFilters(responseJson)
      // this.DisplayedMapTitleTextTarget.innerHTML = responseJson.display_name
    } else {
      console.log('error', response)
    }
  }

  updateAdminMapKeyRadioButtons(mapData) {
    if (this.hasAdminMapKeyRadioButtonsTarget) {
      this.AdminMapKeyRadioButtonsTarget.innerHTML = ''
      mapData.forEach((mapData) => {
        Object.keys(mapData).forEach((mapKey) => {
          const mapKeyRadioContainer = document.createElement('div')
          mapKeyRadioContainer.classList.add('flex', 'items-center')
          mapKeyRadioContainer.setAttribute('data-hybrid-finder-target', 'AdminMapKeyRadioButtonRow')
          mapKeyRadioContainer.setAttribute('data-map-key', mapKey)
          const mapKeyRadioButton = document.createElement('input')
          mapKeyRadioButton.setAttribute('type', 'radio')
          mapKeyRadioButton.setAttribute('name', 'map_key')
          mapKeyRadioButton.setAttribute('value', mapKey)
          mapKeyRadioButton.setAttribute('id', mapKey)
          mapKeyRadioButton.setAttribute('data-map-key', mapKey)
          mapKeyRadioButton.classList.add('mr-2')
          mapKeyRadioButton.addEventListener('click', this.onMapKeyRadioButtonClick.bind(this))

          const mapKeyLabel = document.createElement('label')
          mapKeyLabel.setAttribute('for', mapKey)
          mapKeyLabel.innerHTML = mapKey

          mapKeyRadioContainer.appendChild(mapKeyRadioButton)
          mapKeyRadioContainer.appendChild(mapKeyLabel)
          this.AdminMapKeyRadioButtonsTarget.appendChild(mapKeyRadioContainer)
        })
      })
    }
  }

  onMapKeyRadioButtonClick(e) {
    const mapKey = e.currentTarget.dataset.mapKey
    this.editingMapKey = mapKey

    this.AdminMapKeyRadioButtonRowTargets.forEach((row) =>  row.classList.remove('bg-gray-300'))

    const matchingRadioRow = this.AdminMapKeyRadioButtonRowTargets.find((row) => row.dataset.mapKey === mapKey)
    if (matchingRadioRow) {
      matchingRadioRow.classList.add('bg-gray-300')
    }

    this.resetCountyhighlighting()
    this.highlightCountiesForMapKey(mapKey)
  }

  async onCreateNewMapKey(e) {
    if (this.isAdminEditing) {
      const mapKey = this.AdminNewMapKeyTarget.value

      const request = new FetchRequest('POST', `/hybrids/${this.selectedHybridJson.id}/create_map_data?map_key=${mapKey}`, { responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const responseJson = await response.json

        this.filteredHybridMapData = responseJson

        this.updateAdminMapKeyRadioButtons(responseJson)

        // this.updateMapAndView(responseJson)
        // this.DisplayedMapTitleTextTarget.innerHTML = responseJson.display_name
      } else {
        console.log('error', response)
      }
    }
  }

  onPurposeClick(e) {
    const purpose = e.currentTarget.value.toLowerCase()
    this.selectedPurpose = purpose

    if (this.isAdminEditing) {
      this.showAdminEditInstructions()
    }
  }

  showAdminEditInstructions() {
    if (this.isAdminEditing) {
      if (this.selectedHybridJson === null) {
        this.AdminEditInstructionsStep1Target.classList.remove('hidden')
        this.AllListingsContainerTarget.classList.add('!border-4', '!border-red-500')
      } else {
        this.AdminEditInstructionsStep1Target.classList.add('hidden')
        this.AllListingsContainerTarget.classList.remove('!border-4', '!border-red-500')
        this.enableAdminEditControls()
      }

      if (this.filteredHybridMapData != []) {
        this.AdminMapKeyControlsContainerTarget.classList.remove('hidden')
      }
    } else {
      // remove this when wiring up filter controls
      if (this.filteredHybridMapData != []) {
        this.AdminMapKeyControlsContainerTarget.classList.remove('hidden')
      }
    }
  }

  enableAdminEditControls() {
    this.map.addControl(this.drawControl);
  }

  hideAdminEditControls() {
    this.map.removeControl(this.drawControl);
  }

  highlightMapCountiesForFilters(mapData) {
    let purposes = []
    this.selectedPurpose == null ? purposes = ['silage', 'grain'] : purposes.push(this.selectedPurpose)

    if (mapData.length > 0) {
      mapData.forEach((mapData) => {
        Object.keys(mapData).forEach((mapKey) => {
          if (mapKey) {
            const mapKeyPurpose = mapKey.split('_')[1] // map keys can look like 'purpose_silage', 'purpose_grain', 'purpose_grain_drought_tolerance_irrigated', etc
            if (purposes.includes(mapKeyPurpose)) {
              this.highlightCountiesList(mapData[mapKey], mapKeyPurpose)
            }
          }
        })
      })
    }
  }

  highlightCountiesForMapKey(mapKey) {
    const purpose = this.selectedPurpose || mapKey.split('_')[1]

    if (this.filteredHybridMapData != []) {
      this.filteredHybridMapData.forEach((filteredHybridMapObject) => {
        if (filteredHybridMapObject[mapKey]) {
          this.highlightCountiesList(filteredHybridMapObject[mapKey], purpose)
        }
      })
    }
  }

  highlightCountiesList(countiesList, purpose) {
    this.countyLayers.forEach((countyLayer) => {
      const countyGeoId = countyLayer.feature.properties.GEOID;
      if (countiesList.includes(countyGeoId)) {
        // Default to grain or silage colors
        let style = { fillOpacity: this.fillOpacity, fillPattern: null, fillColor: purpose === 'silage' ? this.fillColorSilage : this.fillColorGrain };

        // Check if the county has both purposes
        if (
          countyLayer.options.fillOpacity === this.fillOpacity &&
            (purpose === 'silage' && countyLayer.options.fillColor === this.fillColorGrain) ||
            (purpose === 'grain' && countyLayer.options.fillColor === this.fillColorSilage)
        ) {
          // apply pattern
          style = { fillOpacity: this.fillOpacity, fillPattern: this.stripedPattern };
        }

        if (this.isFindingDeadzones) {
          style['fillColor'] = '#ff0000'
        }

        countyLayer.setStyle(style);
        this.countyGroup.addLayer(countyLayer);
      }
    });
  }

}