import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    tableId: String,
    rowFilterId: String,
  }

  static targets = ["selectInput"]

  yieldMapController() {
    var div = document.getElementById("yield-map-container")
    return this.application.getControllerForElementAndIdentifier(div, "yield-map")
  }

  titleizedRowFilterIdValue() {
    return this.rowFilterIdValue.charAt(0).toUpperCase() + this.rowFilterIdValue.slice(1)
  }

  onFilterChange(e) {
    e.preventDefault()

    this.filterByState(e.target.value)
  }

  filterByState(state) {
    var filterValue = state
    var table = document.getElementById(this.tableIdValue)
    var rows = Array.prototype.slice.call(table.querySelectorAll(`[data-row-filter-${this.rowFilterIdValue}]`))

    this.selectInputTarget.value = state
    rows.map((row) => {
      if (filterValue == "") {
        row.classList.remove("hidden")
      } else if (row.dataset[`rowFilter${this.titleizedRowFilterIdValue()}`] != filterValue) {
        row.classList.add("hidden")
      } else {
        row.classList.remove("hidden")
      }
    })

    this.yieldMapController().stateSelectionValue = state
    this.setQueryString(state)
  }

  setQueryString(state) {
    if (state !== "") {
      window.history.replaceState({}, document.title, "/results?yield_state=" + state);
    } else {
      window.history.replaceState({}, document.title, "/results");
    }
  }

}
